<template>
  <div>
    <v-row>
      <v-col cols="12">
        <Table
          :servicePath="'/invoice/list/'"
          :headers="th"
          :title="tableTitle()"
          :itemsPerPageSent="itemsPerPageSent"
          :sortByKey="sortBy"
          :sortDesc="sortDesc"
          tableType="invoices"
          tableUser="customer"
          icon="mdi-file"
          :reload="reload"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from "@/components/shared/Table";
export default {
  components: {
    Table
  },
  data: function() {
    return {
      sortBy: ["date"],
      sortDesc: [true],
      itemsPerPageSent: 50,
      th: this.tableHeaders(),
      tt: this.tableTitle(),
      sendInvoicePopup: false,
      editInvoicePopup: false,
      invoice: false,
      reload: 0
    };
  },
  watch: {
    $route() {
      this.path = this.$route.path.replace('customer/invoices/','');
      this.path = this.path.replace( /[0-9]/g, '')
      this.th = this.tableHeaders()
    },
    '$i18n.locale'() {
      this.l = this.$i18n.messages[this.$i18n.locale]['$vuetify'];
      this.th = this.tableHeaders(),
      this.tt = this.tableTitle()
    }
  },
  beforeCreate() {
    this.path = this.$route.path.replace('customer/invoices/','')
    this.path = this.path.replace( /[0-9]/g, '')
  },
  methods: {
    editInvoice(i) {
      this.invoice = i;
      this.editInvoicePopup = true
    },
    sendInvoice(i) {
      this.invoice = i;
      this.sendInvoicePopup = true
    },
    tableTitle() {
      var l = this.$i18n.messages[this.$i18n.locale]['$vuetify'];
      return this.path == 'barcode/' ? l.navigations.gs1_solutions_invoices : l.navigations.gs1_invoices;
    },
    tableHeaders() {
      var p = this.path.replace('/','')
      p = p.replace('/','')
      var b = p == 'barcode' ? '' : ' d-none';
      var m = p == 'membership' ? '' : ' d-none';
      var l = this.$i18n.messages[this.$i18n.locale]['$vuetify'];
      return [
        
        {
          text: l['tables']['invoice_id'],
          value: "id"
        },
        {
          text: l['tables']['company'],
          value: "company.name"
        },
        {
          text: l['tables']['year'],
          value: "year",
          align: m
        },
        {
          text: l['tables']['amount'],
          value: "amount"
        },
        {
          text: l['company']['barcode_count'],
          value: "barcode_count",
          align: b
        },
        {
          text: l['tables']['date'],
          value: "insert_date"
        },
        {
          text: l['action']['action'],
          value: "invoiceActions",
          sortable: false
        }
      ];
    }
  }
};
</script>